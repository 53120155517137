

import React from 'react';
import PropTypes from 'prop-types';
import {
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from 'react-share';
import {
    // EmailIcon,
    FacebookIcon,
    // InstapaperIcon,
    // LineIcon,
    LinkedinIcon,
    // LivejournalIcon,
    // MailruIcon,
    // OKIcon,
    // PinterestIcon,
    // PocketIcon,
    // RedditIcon,
    // TelegramIcon,
    // TumblrIcon,
    TwitterIcon,
    // ViberIcon,
    // VKIcon,
    // WeiboIcon,
    WhatsappIcon,
    // WorkplaceIcon,
  } from "react-share";


import "assets/scss/share.scss";

const Share = ({ socialConfig, tags }) => (
	<div className="post-social">
		<FacebookShareButton url={socialConfig.config.url} className="button is-outlined is-rounded facebook" >
			<span className="icon">
				<FacebookIcon size={32} round={true}/>
			</span>
		</FacebookShareButton>
		<TwitterShareButton url={socialConfig.config.url} className="button is-outlined is-rounded twitter" title={socialConfig.config.title} via={socialConfig.twitterHandle.split('@').join('')} hashtags={tags} >
			<span className="icon">
            <TwitterIcon size={32} round={true}/>
			</span>
		</TwitterShareButton>
		<LinkedinShareButton url={socialConfig.config.url} className="button is-outlined is-rounded linkedin" title={socialConfig.config.title} >
			<span className="icon">
            <LinkedinIcon size={32} round={true}/>
			</span>
		</LinkedinShareButton>
		<WhatsappShareButton url={socialConfig.config.url} className="button is-outlined is-rounded whatsapp" title={socialConfig.config.title} >
			<span className="icon">
				<WhatsappIcon size={32} round={true} />
			</span>
		</WhatsappShareButton>
	</div>
);

Share.propTypes = {
	socialConfig: PropTypes.shape({
		twitterHandle: PropTypes.string.isRequired,
		config: PropTypes.shape({
			url: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}),
	}).isRequired,
	tags: PropTypes.arrayOf(PropTypes.string),
};
Share.defaultProps = {
	tags: [],
};

export default Share;

